import React, { useEffect } from "react"
import Tags from "../../Doc/MoreOptions/tags"
import { getSelectOptions } from "../helpers"
import FeaturedImage from "./featuredImage"
import SeoDescription from "./SeoDescription"

const MoreOptions = ({ states, setStates, setFeaturedImage, setToDeleteFiles }) => {
  const updateData = (e, fieldName, value) => {
    if (e !== undefined && e.target.type !== "checkbox") {
      e.preventDefault()
    }
    value = value === undefined ? e.target.value : value
    setStates({ ...states, moreOptions: { ...states?.moreOptions, [fieldName]: value } })
  }

  useEffect(() => {
    if (!states.moreOptions?.isVisibleMarketplace) {
      setStates({
        ...states,
        moreOptions: {
          ...states.moreOptions,
          isVisible: "false",
          isVisibleMarketplace: "true",
          isTrackInventory: "false",
        },
      })
    }
  }, [])

  const setTags = tags => {
    updateData(undefined, "tags", tags)
  }

  const doNothing = e => {
    console.log("dans do nothing ", e.target.checked, " ", states.moreOptions?.isVisible)
  }

  return (
    <div className="col-lg-12 mb-3" id="more_panel">
      <div className="accordion" id="morePanelAccordion">
        <div className="accordion-item panel panel-grey">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#more_container"
              aria-expanded="true"
              aria-controls="more_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-three-dots"></i>
              </span>
              <span className="panel-heading-title">More Options</span>
            </div>
          </div>
          <div
            id="more_container"
            className="accordion-collapse collapse show"
            aria-labelledby="more_container"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-tags-fill"></i> Tags
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <Tags
                          tags={states.moreOptions?.tags || []}
                          setTags={setTags}
                          tempTags={states.moreOptions?.tags || []}
                          setTempTags={setTags}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-gear-fill"></i> Settings
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <div className="row">
                          <div className="col-sm-12 mb-2 greenRedSwich">
                            <div className="toggleSwitch">
                              <input
                                type="checkbox"
                                value="1"
                                name="isVisible"
                                id="isVisibleStore"
                                checked={states.moreOptions?.isVisible !== "false"}
                                className="sc_switch"
                                onChange={e =>
                                  updateData(e, "isVisible", e.target.checked ? "true" : "false")
                                }
                              />
                              <label>
                                Visible in Your Store{" "}
                                <span
                                  data-bs-toggle="tooltip"
                                  title="Visible in your public store and the overall marketplace. Turn off to make this a hidden product."
                                >
                                  <i className="bi bi-question-circle-fill"></i>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-2 greenRedSwich">
                            <div className="toggleSwitch">
                              <input
                                type="checkbox"
                                value="1"
                                name="isTrackInventory1"
                                id="isTrackInventory1"
                                className="sc_switch"
                              />
                              <input
                                type="checkbox"
                                value="1"
                                name="isVisibleMarketplace"
                                id="isVisibleMarketplace"
                                className="sc_switch"
                                checked={states.moreOptions?.isVisibleMarketplace !== "false"}
                                onChange={e =>
                                  updateData(e, "isVisibleMarketplace", e.target.checked ? "true" : "false")
                                }
                              />
                              <label>
                                Visible in Public Marketplace{" "}
                                <span
                                  data-bs-toggle="tooltip"
                                  title="Visible in your public store and the overall marketplace. Turn off to make this a hidden product."
                                >
                                  <i className="bi bi-question-circle-fill"></i>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-2 greenRedSwich">
                            <div className="toggleSwitch">
                              <input
                                type="checkbox"
                                value="1"
                                name="isTrackInventory"
                                id="isTrackInventory"
                                className="sc_switch"
                                checked={states.moreOptions?.isTrackInventory !== "false"}
                                onChange={e =>
                                  updateData(e, "isTrackInventory", e.target.checked ? "true" : "false")
                                }
                              />
                              <label>Track Inventory</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <SeoDescription states={states} setStates={setStates} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <FeaturedImage
                      states={states}
                      setFileToUpload={setFeaturedImage}
                      setToDeleteFiles={setToDeleteFiles}
                    />

                    {/* <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-person-fill"></i> Role of Buyer
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <select className="form-select">
                          <option>== ROLES ==</option>
                          {getSelectOptions(states, "roles")}
                        </select>
                      </div>
                    </div> */}
                    <div className="col-lg-12 mb-3">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-currency-dollar"></i> Currency
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        <div className="bfh-selectbox bfh-currencies" data-currency="EUR" data-flags="true">
                          <select
                            name="skuCurrency"
                            className="form-select"
                            value={states.moreOptions?.currency}
                            onChange={e => updateData(e, "currency")}
                          >
                            <option value="">--Select Currency--</option>
                            {states.currencies?.map(currency => {
                              return (
                                <option value={currency.code} key={currency.id}>
                                  {currency.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="colHeading">
                        <strong>
                          <i className="bi bi-globe"></i> Language
                        </strong>
                      </div>
                      <div className="dh-block-body">
                        Language of this doc:
                        <div
                          className="bfh-selectbox bfh-languages"
                          data-language="en_US"
                          data-available="en_US,fr_CA,es_MX"
                          data-flags="true"
                        >
                          <select
                            name="skuLanguage"
                            className="form-select"
                            value={states.moreOptions.language}
                            onChange={e => updateData(e, "language")}
                          >
                            <option value="default">Default Language</option>
                            {states.languages.map(language => {
                              return (
                                <option value={language.code} key={language.id}>
                                  {language.language}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoreOptions

import React, { useState, useRef, useEffect } from "react"

const FeaturedImage = ({ states, setFileToUpload, setToDeleteFiles }) => {
  const featuredImageRef = useRef(null)
  const [imageSrc, setImageSrc] = useState(`${process.env.GATSBY_WRANGLER_URL}StaticImages/no-image.jpg`)

  const changeImage = e => {
    e.preventDefault()
    featuredImageRef.current.click()
  }

  const addFileToDelete = filename => {
    setToDeleteFiles(prev => {
      return [...prev, filename]
    })
  }

  const onImageChange = e => {
    setFileToUpload(e.target.files[0])

    if (states.moreOptions?.featuredImage !== "") {
      addFileToDelete(states.moreOptions.featuredImage)
    }

    if (e.target.files[0]) {
      const reader = new FileReader()
      reader.onload = () => {
        setImageSrc(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useEffect(() => {
    setImageSrc(
      `${process.env.GATSBY_WRANGLER_URL}${states.moreOptions?.featuredImage || "StaticImages/no-image.jpg"}`
    )
  }, [states.moreOptions?.featuredImage])

  return (
    <div className="col-lg-12 mb-3">
      <div className="colHeading">
        <strong>
          <i className="bi bi-file-image"></i> Featured Image
        </strong>
      </div>
      <div className="dh-block-body">
        <div className="folderIcon">
          <img src={imageSrc} />
        </div>
        <button type="button" className="btn btn-default btnFeaturedImgEdit" onClick={changeImage}>
          <span data-bs-toggle="tooltip" data-bs-title="Edit folder image">
            <i className="bi bi-pencil-fill"></i>
          </span>
        </button>
      </div>
      <input type="file" ref={featuredImageRef} onChange={onImageChange} hidden />
    </div>
  )
}

export default FeaturedImage

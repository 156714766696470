import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import Item from "../../../components/Drive/Item/item"
import Seo from "../../../components/seo"
import ReduxWrapper from "../../../redux/reduxWrapper"

const ItemPage = props => {
  return (
    <React.Fragment>
      <Seo title="Item you sell" />
      <PrivateRoute component={Item} location={props.location} fileUrl={props.fileUrl} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<ItemPage {...props} />} />
export default WrappedPage

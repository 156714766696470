import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const SeoDescription = ({ states, setStates }) => {
  const charLength = 160
  const [count, setCount] = useState(160)

  const updateSeoDescription = e => {
    setCount(charLength - e.target.value.length)
    setStates({ ...states, moreOptions: { ...states.moreOptions, seoDescription: e.target.value } })
  }

  useEffect(() => {
    setCount(charLength - (states.moreOptions?.seoDescription?.length || 0))
  }, [])

  return (
    <>
      <div className="col-lg-12 mb-3">
        <div className="colHeading">
          <strong>
            <i className="bi bi-file-image" /> SEO Description{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Invoke curiosity, show payoff, make us want to click! This shows on Google, Bing, etc.
                </Tooltip>
              }
            >
              <i className="bi bi-question-circle-fill" />
            </OverlayTrigger>
          </strong>
        </div>
        <div className="dh-block-body">
          <textarea
            className="form-control seo_desc"
            defaultValue={states.moreOptions.seoDescription}
            maxLength={charLength}
            name="seo_description"
            onChange={updateSeoDescription}
          />
          <span className="seo_desc_counter">{count} remaining</span>
        </div>
      </div>
    </>
  )
}

export default SeoDescription

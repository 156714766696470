import React from "react"

export const getSelectOptions = (states, entityType, labelField, valueField) => {
  if (labelField === undefined) {
    labelField = "name"
  }

  if (valueField === undefined) {
    valueField = "id"
  }

  return (
    <>
      {states[entityType]?.map(entity => {
        return (
          <option value={entity[valueField]} key={entity.id}>
            {entity[labelField]}
          </option>
        )
      })}
    </>
  )
}

import AxiosInstance from "../../services/axiosInstance"

export const getPrimaryEmail = async () => {
  try {
    const response = await AxiosInstance.get("/settings/general-setting/settings-data")
    if (response.status === 200) {
      return response.data.data.filter(data => data.type === "Primary")
    } else {
      console.error("Unexpected status code:", response.status)
      return null
    }
  } catch (error) {
    console.error("Error:", error)
    return null
  }
}

export const getSkus = async () => {
  try {
    const response = await AxiosInstance.get("/drive/item/")
    if (response.status === 200) {
      return response.data.items
    } else {
      console.error("Unexpected status code:", response.status)
      return null
    }
  } catch (error) {
    console.error("Error:", error)
    return null
  }
}

export const getEvents = async () => {
  const today = new Date()
  const from_date = today.toISOString().split("T")[0]
  try {
    const eventsResponse = await AxiosInstance.get(`/calendar/?from_date=${from_date}`)
    if (eventsResponse.status === 200) {
      return eventsResponse.data.items.map(event => {
        return { id: event.id, title: event.title }
      })
    } else {
      console.error("Unexpected status code:", eventsResponse.status)
      return null
    }
  } catch (error) {
    if (error.response) {
      console.error("Server Error:", error.response.data)
    } else if (error.request) {
      console.error("No response received:", error.request)
    } else {
      console.error("Request setup error:", error.message)
    }
    return null
  }
}

export const getSequences = async () => {
  try {
    const sequencesResponse = await AxiosInstance.get(`/drive/sequence/`)
    if (sequencesResponse.status === 200) {
      return sequencesResponse.data.items.map(seq => {
        return { id: seq.id, name: seq.name }
      })
    } else {
      console.error("Unexpected status code:", sequencesResponse.status)
      return null
    }
  } catch (error) {
    if (error.response) {
      console.error("Server Error:", error.response.data)
    } else if (error.request) {
      console.error("No response received:", error.request)
    } else {
      console.error("Request setup error:", error.message)
    }
    return null
  }
}

export const getInvoices = async () => {
  try {
    const invoicesResponse = await AxiosInstance.get(`/drive/invoice/`)
    if (invoicesResponse.status === 200) {
      return invoicesResponse.data.invoices.map(invoice => {
        return { id: invoice.id, invoiceName: invoice.invoiceName }
      })
    } else {
      console.error("Unexpected status code:", invoicesResponse.status)
      return null
    }
  } catch (error) {
    if (error.response) {
      console.error("Server Error:", error.response.data)
    } else if (error.request) {
      console.error("No response received:", error.request)
    } else {
      console.error("Request setup error:", error.message)
    }
    return null
  }
}

export const getLanguages = async () => {
  try {
    const response = await AxiosInstance.get(`${process.env.GATSBY_API_URL}/language/list`)
    if (response.status === 200) {
      return response.data.data
    } else {
      console.error("Unexpected status code:", response.status)
      return null
    }
  } catch (error) {
    if (error.response) {
      console.error("Server Error:", error.response.data)
    } else if (error.request) {
      console.error("No response received:", error.request)
    } else {
      console.error("Request setup error:", error.message)
    }
    return null
  }
}

export const getCurrencies = async () => {
  try {
    const response = await AxiosInstance.get(`${process.env.GATSBY_API_URL}/currency/list`)
    if (response.status === 200) {
      return response.data.data
    } else {
      console.error("Unexpected status code:", response.status)
      return null
    }
  } catch (error) {
    if (error.response) {
      console.error("Server Error:", error.response.data)
    } else if (error.request) {
      console.error("No response received:", error.request)
    } else {
      console.error("Request setup error:", error.message)
    }
    return null
  }
}

export const getSpreadsheets = async () => {
  try {
    const response = await AxiosInstance.get(`/drive/list-sheet`)

    if (response.status === 200) {
      return response.data.items
    } else {
      console.error("Unexpected status code:", response.status)
      return null
    }
  } catch (error) {
    if (error.response) {
      console.error("Server Error:", error.response.data)
    } else if (error.request) {
      console.error("No response received:", error.request)
    } else {
      console.error("Request setup error:", error.message)
    }
    return null
  }
}

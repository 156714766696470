import React, { useEffect, useState } from "react"

const ItemPrice = ({ states, setStates, index }) => {
  useEffect(() => {
    if (states.prices.length < index + 1) {
      setStates({
        ...states,
        prices: [
          ...states.prices,
          {
            optionalPromoText: "",
            paymentInstallment: "OneTimePayment",
            singlePrice: 0.0,
            priceType: "SalePrice",
          },
        ],
      })
    }
  }, [])

  const updateData = (e, fieldName, callback) => {
    const prices = states.prices.map((price, i) => {
      if (index === i) {
        price[fieldName] = e.target.value
        if (callback !== undefined) {
          price = callback(price)
        }
      }
      return price
    })
    setStates({ ...states, prices })
  }

  const deletePrice = index => {
    setStates({
      ...states,
      prices: states.prices.filter((price, i) => {
        return i !== index
      }),
    })
  }

  const setSplitPaymentDefaultValues = price => {
    if (price.paymentInstallment === "splitPayment") {
      price = { ...price, splitPaymentPeriod: "Month", splitPaymentInstallment: 2 }
    }
    return price
  }

  return (
    <div className="itemBox">
      {index !== 0 && (
        <button
          className="btnDeleteItemFeed"
          type="button"
          data-bs-toggle="tooltip"
          title="Delete"
          onClick={e => deletePrice(index)}
        >
          <i className="bi bi-x-lg"></i>
        </button>
      )}
      <div className={`mb-3 row product_price_${Date.now()}`}>
        <div className="col-lg-12">
          <div className="mb-3 row align-items-center">
            <label className="col-sm-4 col-form-label">Type</label>
            <div className="col-sm-8">
              {index === 0 && "Price (Default)"}
              {index !== 0 && (
                <div className="row align-items-center">
                  <div className="col-lg-3">
                    <select
                      name="productPriceTypes[]"
                      className="form-select priceType"
                      value={states.prices[index].priceType}
                      onChange={e => updateData(e, "priceType")}
                    >
                      <option value="SalePrice">Sale Price</option>
                      <option value="AltPrice">Alt. Price</option>
                      <option value="VolumeDiscount">Volume Discount Price</option>
                      <option value="CouponPrice">Coupon Price (Hidden)</option>
                      <option value="MembersPrice">Member's Price</option>
                      <option value="InternalCost">Internal Cost (Secret)</option>
                    </select>
                  </div>
                  <div className="col-lg-9">
                    {/* <!-- SHOW ALL / ONLY SPREADSHEETS HERE --> */}
                    <select name="volumePricingSheet[]" className="form-select display-none volumePricing">
                      <option value="" disabled="">
                        Select Pricing Sheet
                      </option>
                    </select>
                    <a
                      href="https://crm.swiftcrm.com/support/volume-pricing"
                      target="_blank"
                      className="volumePricingTooltip display-none"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        data-animation="false"
                        title=""
                        data-original-title="Ensure your spreadsheet has 2 columns: Quantity &amp; Price"
                      >
                        <i className="bi bi-question-circle-fill"></i>
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* index !== 0 && (
                        <>
                        <div className="mb-3 row couponCodeRow display-none dynamicRow">
                            <label for="price" className="col-sm-4 col-form-label">Coupon Code(s) to Honor </label>
                            <div className="col-sm-8">
                                <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                                    <input className="form-control" type="text" name="couponCode[]"/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 row membersPriceRow display-none dynamicRow">
                            <label for="price" className="col-sm-4 col-form-label">Require open &amp; valid agreement for any of...</label>
                            <div className="col-sm-8">
                                <div className="mb-2 mr-sm-2 mb-sm-0">
                                    <select className="form-select" name="membersPrice[]">
                                        <option value="" disabled="">Show all Agreements Here</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        </>
                    )*/}
          <div className="mb-3 row align-items-center">
            <label className="col-sm-4 col-form-label">Optional Promo Text</label>
            <div className="col-sm-8">
              <input
                type="text"
                name="optionalPromoText"
                placeholder="My promo text here..."
                className="form-control"
                value={states?.prices[index]?.optionalPromoText || ""}
                onChange={e => updateData(e, "optionalPromoText")}
              />
            </div>
          </div>
          <div className="mb-3 row align-items-center">
            <label className="col-sm-4 col-form-label">Price(s)</label>
            <div className="col-sm-8">
              {index === 0 && (
                <div className="row">
                  <div className="col-lg-3 mb-2 mb-md-0">
                    <select
                      name="paymentInstallment"
                      id="paymentInstallment"
                      className="form-select"
                      value={states?.prices[index]?.payementInstallment}
                      onChange={e => updateData(e, "paymentInstallment", setSplitPaymentDefaultValues)}
                    >
                      <option value="OneTimePayment">One Time Payment</option>
                      {states?.allowSplitInstallment && (
                        <option value="splitPayment">Split into Payments</option>
                      )}
                    </select>
                  </div>
                  <div className="col-lg-9">
                    <div className="input-group paymentInstallmentOpt ${states.prices[index].paymentInstallment}">
                      <span className="input-group-text">
                        <i className="bi bi-currency-dollar"></i>
                      </span>
                      <input
                        type="number"
                        name="singlePrice"
                        id="singlePrice"
                        min="0"
                        step="any"
                        value={states?.prices[index]?.singlePrice || 0}
                        className="form-control"
                        onChange={e => updateData(e, "singlePrice")}
                      />

                      {states.prices[index].paymentInstallment === "splitPayment" && (
                        <>
                          <span className="input-group-text">/</span>
                          <select
                            name="splitPaymentPeriod"
                            id="splitPaymentPeriod"
                            className="form-select"
                            value={states.prices[index].splitPaymentPeriod}
                            onChange={e => updateData(e, "splitPaymentPeriod")}
                          >
                            <option value="Month">Month</option>
                            <option value="Week">Week</option>
                            <option value="Quarter">Quarter</option>
                          </select>
                          <span className="input-group-text">for</span>
                          <select
                            name="splitPaymentInstallment"
                            id="splitPaymentInstallment"
                            className="form-select"
                            value={states.prices[index].splitPaymentInstallment}
                            onChange={e => updateData(e, "splitPaymentInstallment")}
                          >
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">6</option>
                            <option value="6">6</option>
                            <option value="12">12</option>
                            <option value="24">24</option>
                            <option value="36">36</option>
                            <option value="48">48</option>
                            <option value="72">72</option>
                            <option value="84">84</option>
                            <option value="120">120</option>
                          </select>
                          <span className="input-group-text">payments</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {index !== 0 && (
                <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                  <div className="input-group-text">$</div>
                  <input
                    className="form-control"
                    type="number"
                    name="singlePrice[]"
                    value={states?.prices[index]?.singlePrice || 0}
                    onChange={e => updateData(e, "singlePrice")}
                  />
                  <div className="input-group-text TermOptsDivider d-none">/</div>
                  <select
                    className="form-control termOpts d-none"
                    name="singlePriceTrailPeriod[]"
                    id="TermOpts"
                  >
                    <option value="month">Month</option>
                    <option value="year">Year</option>
                    <option value="quarter">Quarter</option>
                    <option value="week">Week</option>
                    <option value="3years">3 Years</option>
                    <option value="10years">10 Years</option>
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemPrice
